<template>
  <!-- Single Step Page -->
  <PreviewSpiner v-if="!ready"/>
  <div class="create-page" v-else>
    <CForm
      v-on:submit.prevent="createSingleStep"
      novalidate
      class="create-page__form"
    >
      <FormBuilder
        :list="formList"
        @updated="updateSettingsData"
        :page="{ title: texts.createPage.title, info: descriptions }"
      />
    </CForm>
  </div>
  <!-- /Single Step Page -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import fieldHelpers from '@/utils/ZiqniFieldHelper';
import { stepSubTitles } from '@/config/pageTexts/stepSubTitles.json';
import { events } from '@/config/descriptions/events.json';
import { eventsTexts } from '@/config/pageTexts/events.json';
import eventFields from '@/generated/ziqni/store/modules/events/fields';
import {  delay } from 'lodash';
import PreviewSpiner from "@/shared/UI/Spiner";

export default {
  name: 'CreateEvent',
  components: {
    PreviewSpiner,
  },
  data() {
    return {
      model: 'events',
      ready: true,
      currentStep: 0,
      totalStep: 0,
      descriptions: {
        ...events.create,
      },
      texts: {
        ...eventsTexts,
      },
      steps: [],
      stepKeys: [],
      firstStep: {
        title: 'Settings',
        subTitle: stepSubTitles.settings,
        step: 0,
      },
      lastStep: {
        title: 'Summary',
        subTitle: stepSubTitles.summary,
        key: 'summary',
        step: 2,
      },
      formList: [],
      settingsData: {},
      translationsData: {},
      translationsMap: {},
      requiredFields: [],
      translatableFields: [],
    };
  },
  computed: {
    ...mapGetters('events', ['message', 'loading']),
    isButtonDisabled() {
      if (this.loading) return true;
      return !!this.message;
    },
  },
  provide() {
    return {
      stepKeys: this.stepKeys,
      model: this.model,
    }
  },
  watch: {
    message(val) {
      if (val === this.texts.createPage.duplicateMessage) {
        this.idValidate = false;
        this.invalidRefIdFeedback = null;
      } else if (val === this.texts.createPage.emptyMessage) {
        this.idValidate = false;
        this.invalidRefIdFeedback = null;
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions(
      'events',
      [
        'handleCreateEvents',
      ]
    ),
    initialize() {
      routerBreadcrumbs(this.$router.currentRoute);

      this.formList = fieldHelpers.prepareCreateFormList(
        eventFields,
        eventsTexts.createPage,
        events.create
      );

      let formSteps = [];

      this.formList.forEach(field => {
        if (field.type.indexOf('_OBJECT') !== -1) {
          formSteps.push(field);
        }
        if (field.required) {
          this.requiredFields.push(field.key);
          if (field.type === 'NAMEKEY') {
            this.requiredFields.push('name');
          }
        }
      })

      if (formSteps.length) {
        let objectTypes = [];
        formSteps.forEach(step => {
          objectTypes.push(step.type)
          if (step.key === 'scheduling') {
            this.requiredFields.scheduling = [];
            this.requiredFields.scheduling.push('scheduleType')
          }
        });
        this.formList = this.formList.filter(formItem => {
          return !objectTypes.includes(formItem.type)
        });

        this.steps.push(this.firstStep);
        let stepNumber = 1

        formSteps.forEach(step => {
          this.steps.push({
            title: step.label,
            subTitle: stepSubTitles[step.key],
            key: step.key,
            step: stepNumber,
          });
          this.stepKeys.push(step.key);

          stepNumber++;
        })

        this.translatableFields = eventFields.baseFields.translatableFields;

        this.lastStep.step = stepNumber;
        this.steps.push(this.lastStep);
        this.totalStep = formSteps.length + 1;
      }
    },
    updateCurrentStep(val) {
      this.currentStep = val;
    },
    nextStep() {
      let invalidFields = this.getInvalidFields(true);
      if (!invalidFields.length) {
        this.currentStep += 1;
      } else {
        this.setInvalidFields(invalidFields);
      }
    },
    getInvalidFields() {
      let result = [];
      this.settingsData.constraints = [];

      for (let key in this.settingsData) {
        if (this.requiredFields.includes(key) && (this.settingsData[key] === null || this.settingsData[key] === '')) {
          result.push(key);
        }
      }
      return result;
    },
    updateSettingsData(val) {
      this.settingsData = val;
    },
    setInvalidFields(invalidFields) {
      console.log('invalidFields:', invalidFields)
      invalidFields.forEach(invalidField => {
        console.log('invalidField:', invalidField)
        let invalidFieldElement = document.getElementsByName(invalidField)[0];
        let invalidElement = invalidFieldElement;
        if (!invalidFieldElement.classList.contains('zq--form-row')) {
          invalidElement = invalidFieldElement.parentNode;
        }
        invalidElement.classList.add('zq-invalid');
      })
    },
    getRules() {
      let rules = [];
      for (const action in this.rulesData) {
        let ruleObject = {};

        ruleObject.entityId = '';
        ruleObject.action = action;
        ruleObject.context = 'event';
        ruleObject.rules = this.rulesData[action];
        ruleObject.rules.type = 'condition';
        ruleObject.rules.lineNumber = 1;

        rules.push(ruleObject);
      }

      return rules
    },
    createSingleStep() {
      let invalidFields = this.getInvalidFields();
      if (!invalidFields.length) {
        this.createEntity();
      } else {
        this.setInvalidFields(invalidFields)
      }
    },
    createEntity() {
      let formData = {};

      if (Object.keys(this.settingsData).length) {
        delete this.settingsData.constraints;
        formData = {...this.settingsData};
      }

      const body = [];
      body.push(JSON.parse(JSON.stringify(formData)));

      this.handleCreateEvents({createEventRequestArray: body})
        .then(data => {
          if (data.length) {
            this.ready = false;
            delay(() => {
              this.$router.push({
                name: 'PreviewEvent',
                params: {
                  id: data[0].id,
                }
              })
            }, 2000);
          } else {
            console.log('Something went wrong');
          }
        });
    },
  },
};
</script>

<style lang="scss">
.create-page {
  &__header {
    background-color: var(--zq-main-bg);
  }
  .zq-invalid {
    .form-control {
      border: solid 1px var(--zq-warn);
    }
  }
}
</style>
